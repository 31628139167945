.profile__page {
  justify-content: flex-start !important;
  @import "../pageComponents/userData";
  @import "../pageComponents/timeTable";

  .profile__info {
    width: 100%;
    background: #F3F3F3;
    .cnt {
      padding-top: 30px;
      padding-bottom: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      overflow: visible;
      &:before {
        content: url("../../../assets/image/vmeste/profile__line.png");
        position: absolute;
        left: 0;
        bottom: -21px;
        @include for-phone {
          display: none; } } }
    .ava {
      width: 200px;
      height: 200px;
      border-radius: 200px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        &.cover {
          object-fit: cover; } } }
    .name {
      margin-top: 30px;
      font-size: 36px; } }


  .elka {
    margin-top: 81px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url("../../../assets/image/vmeste/elka__bg.png") no-repeat center;
    background-size: cover;
    border-radius: 20px;
    padding: 15px 90px 15px 50px;
    @include for-phone {
      margin-top: 40px;
      padding: 15px;
      flex-direction: column;
      justify-content: center; }
    &__img {
      flex-shrink: 0;
      margin-left: 100px;
      @include for-phone {
        margin-left: 0;
        margin-top: 50px; } }

    &__desc {
      color: #fff; }
    &__head {
      font-size: 48px;
      line-height: 45px;
      font-weight: bold;
      @include for-phone {
        font-size: 32px;
        line-height: 1.2; } }
    &__text {
      margin-top: 25px;
      font-size: 18px;
      line-height: 24px;
      @include for-phone {
        font-size: 14px;
        line-height: 1.2; } }
    &__footer {
      margin-top: 20px;
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      @include for-phone {
        font-size: 16px;
        line-height: 1.2; } } } }

.questionnaire__iframe {
  height: 1000px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0; }
  @include for-phone {
    height: 500px; } }



