.banner {
  width: 100%;
  min-height: 735px;
  //background: url(../../../image/bannerBg.png) no-repeat center bottom
  //background-size: cover
  position: relative;
  @include for-desktop {
    min-height: 650px; }
  @include for-phone {
    min-height: auto;
    padding-bottom: 150px; }
  .cnt {
    position: relative;
    z-index: 2;
    padding-top: 33px; }
  &__bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  &__bgPeople {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 1149px;
    height: 432px;
    object-fit: contain;
    object-position: bottom right;
    @include for-desktop {
      width: 800px; }
    @include for-tablet {
      width: 500px; }
    @include for-phone {
      width: 100%;
      object-fit: contain; } }

  &__date {
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #222222;
    margin-bottom: 19px;
    @include for-phone {
      font-size: 14px;
      line-height: 18px; } }

  &__head {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #FF6700;
    width: 55%;
    @include for-desktop-little {
      width: 60%; }
    @include for-phone {
      font-size: 24px;
      line-height: 28px;
      width: 100%; } }
  &__text {
    margin-top: 24px;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    width: 50%;
    @include for-phone {
      font-size: 14px;
      line-height: 18px;
      width: 100%; } }
  .button {
    margin-top: 30px; }


  &.bvmeste {
    color: #fff;
    @include for-phone {
      padding-top: 100px !important;
      padding-bottom: 0; }
    .banner__head {
      color: #fff;
      width: 50%;
      font-size: 64px;
      line-height: 72px;
      font-weight: normal;
      @include for-phone {
        width: 100%;
        font-size: 30px;
        line-height: 1.2; } }
    .banner__subHead {
      width: 50%;
      font-weight: bold;
      font-style: italic;
      font-size: 36px;
      line-height: 42px;
      margin-top: 6px;
      @include for-phone {
        width: 100%;
        font-size: 18px;
        line-height: 1.2; } }
    .banner__text2 {
      width: 50%;
      margin-top: 12px;
      font-size: 16px;
      line-height: 19px;
      @include for-phone {
        width: 100%;
        font-size: 14px;
        line-height: 1.2; } }
    .banner__text3 {
      width: 50%;
      margin-top: 25px;
      font-weight: bold;
      font-size: 22px;
      line-height: 25px;
      @include for-phone {
        width: 100%;
        font-size: 16px;
        line-height: 1.2; } }
    .banner__text4 {
      width: 50%;
      margin-top: 32px;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      @include for-phone {
        font-size: 18px;
        line-height: 1.2;
        width: 100%; } }
    .banner__foter {
      position: relative;
      z-index: 10;
      background-color: rgba(#FF6700, .5);
      margin-top: 30px;
      .cnt {
        padding-top: 32px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 36px;
        line-height: 42px;
        @include for-phone {
          font-size: 18px;
          line-height: 1.2;
          flex-direction: column;
          padding-top: 16px;
          padding-bottom: 10px; } } }

    .banner__btn {
      display: inline-block;
      font-size: 24px;
      line-height: 28px;
      padding: 16px 27px;
      background: #fff;
      color: #FF6700;
      border-radius: 13px;
      text-decoration: none;
      text-transform: uppercase;
      @include for-phone {
        font-size: 16px;
        line-height: 1.2;
        width: 100%;
        text-align: center;
        margin-top: 15px; } } } }
