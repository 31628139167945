@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-UltraLight.woff2') format('woff2'),
  url('../fonts/SuisseIntl-UltraLight.woff') format('woff') {}
  font-weight: 200;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-LightItalic.woff2') format('woff2'),
  url('../fonts/SuisseIntl-LightItalic.woff') format('woff') {}
  font-weight: 300;
  font-style: italic;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-BoldItalic.woff2') format('woff2'),
  url('../fonts/SuisseIntl-BoldItalic.woff') format('woff') {}
  font-weight: bold;
  font-style: italic;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-UltraLightItalic.woff2') format('woff2'),
  url('../fonts/SuisseIntl-UltraLightItalic.woff') format('woff') {}
  font-weight: 200;
  font-style: italic;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl Book';
  src: url('../fonts/SuisseIntl-Book.woff2') format('woff2'),
  url('../fonts/SuisseIntl-Book.woff') format('woff') {}
  font-weight: normal;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-SemiBold.woff2') format('woff2'),
  url('../fonts/SuisseIntl-SemiBold.woff') format('woff') {}
  font-weight: 600;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-Thin.woff2') format('woff2'),
  url('../fonts/SuisseIntl-Thin.woff') format('woff') {}
  font-weight: 100;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-BlackItalic.woff2') format('woff2'),
  url('../fonts/SuisseIntl-BlackItalic.woff') format('woff') {}
  font-weight: 900;
  font-style: italic;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/SuisseIntl-SemiBoldItalic.woff') format('woff') {}
  font-weight: 600;
  font-style: italic;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-MediumItalic.woff2') format('woff2'),
  url('../fonts/SuisseIntl-MediumItalic.woff') format('woff') {}
  font-weight: 500;
  font-style: italic;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-Light.woff2') format('woff2'),
  url('../fonts/SuisseIntl-Light.woff') format('woff') {}
  font-weight: 300;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-Bold.woff2') format('woff2'),
  url('../fonts/SuisseIntl-Bold.woff') format('woff') {}
  font-weight: bold;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-Regular.woff2') format('woff2'),
  url('../fonts/SuisseIntl-Regular.woff') format('woff') {}
  font-weight: normal;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl Book';
  src: url('../fonts/SuisseIntl-BookItalic.woff2') format('woff2'),
  url('../fonts/SuisseIntl-BookItalic.woff') format('woff') {}
  font-weight: normal;
  font-style: italic;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-Italic.woff2') format('woff2'),
  url('../fonts/SuisseIntl-Italic.woff') format('woff') {}
  font-weight: normal;
  font-style: italic;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-Medium.woff2') format('woff2'),
  url('../fonts/SuisseIntl-Medium.woff') format('woff') {}
  font-weight: 500;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-ThinItalic.woff2') format('woff2'),
  url('../fonts/SuisseIntl-ThinItalic.woff') format('woff') {}
  font-weight: 100;
  font-style: italic;
  font-display: swap; }


@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-Black.woff2') format('woff2'),
  url('../fonts/SuisseIntl-Black.woff') format('woff') {}
  font-weight: 900;
  font-style: normal;
  font-display: swap; }


