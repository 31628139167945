.header {
  width: 100%;
  border-bottom: 1px solid rgba(#000, .2);
  .cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    @include for-phone {
      overflow: visible; } }


  &__yellow {
    background: #FFD93A; }
  &__orange {
    background: #FF6700; }
  &__logo {
    margin-right: 28px;
    flex-shrink: 0;
    @include for-phone {
      order: 1; } }
  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include for-phone {
      order: 2;
      justify-content: flex-end; } }
  &__navigation {
    width: 100%;
    @include for-phone {
      order: 3;
      width: auto; }
    &-burger {
      display: none;
      cursor: pointer;
      @include for-phone {
        display: block; } }
    .navigation {
      &__list {
        display: none;
        justify-content: flex-start;
        align-items: center;

        &.show {
          display: flex;
          @include for-phone {
            position: absolute;
            z-index: 333;
            bottom: 0;
            right: 0;
            transform: translate(0, 100%) scaleY(0);
            width: 100vw;
            height: auto;
            padding: 20px;
            flex-direction: column;
            justify-content: center;
            background: #FF6700;
            transform-origin: top center;
            animation: .5s ease .1ms forwards showMenu;
            @keyframes showMenu {
              from {
                transform: translate(0, 100%) scaleY(0); }

              to {
                transform: translate(0, 100%) scaleY(1); } } } }
        &-el {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #fff;
          text-decoration: none;
          margin-right: 43px;
          border-bottom: 1px solid rgba(#000, 0);
          @include for-phone {
            text-align: center;
            margin-right: 0;
            margin-bottom: 15px; }
          &:hover {
            border-bottom: 1px solid rgba(#000, 1); }
          &:last-child {
            margin-right: 0;
            margin-bottom: 0; } } } } }
  &__account {
    flex-shrink: 0;
    @include for-phone {
      order: 2; }
    &-link {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      svg {
        margin-left: 28px;
        @include for-phone {
          margin-left: 0;
          margin-right: 18px; } } }
    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #fff;
      text-decoration: none;
      white-space: pre-line;
      @include for-phone {
        display: none; } } } }



