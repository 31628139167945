@import "src/assets/sass/mixin";
.chatBot {
  position: fixed;
  z-index: 333;
  bottom: 50px;
  right: 20px;
  @include for-phone {
    right: 0; }



  &_questions {
    display: none; }

  &_btn {
    font-size: 1.8rem;
    order: 1;
    background: #ff6700;
    border: 0 none;
    color: #fff;
    cursor: pointer;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 2s linear 0s infinite alternate move_eye;


    @keyframes move_eye {
      0% {
        transform: scale(1); }
      25% {
        transform: scale(1.2); }
      50% {
        transform: scale(1); } }


    svg {
      height: 40px;
      width: 40px;
      flex-shrink: 0; } }

  &_close {
    position: absolute;
    top: 15px;
    right: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 1px;
      width: 100%;
      background: #000;
      transition: .5s; }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg); }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg); }
    &:hover {
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); } } }


  &_block {
    padding: 60px 20px 20px;
    width: 400px;
    height: calc(100vh - 170px);
    max-height: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 15px 40px rgba(#000, .6);
    @include for-phone {
      width: 100%; }

    &_answerArea {
      width: 100%;
      overflow-y: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;


      &_question {
        display: inline-block;
        font-size: 16px;
        width: auto;
        max-width: 90%;
        padding: 5px 10px;
        background: #ff6700;
        border-radius: 10px;
        color: #fff;
        margin-bottom: 5px;
        text-align: right;
        position: relative;
        left: 100%;
        transform: translate(-100%); }

      &_answer {
        font-size: 16px;
        display: inline-block;
        width: auto;
        max-width: 90%;
        //background: #18c139
        background: lightgrey;
        padding: 5px 10px;
        border-radius: 10px;
        color: #000;
        margin-bottom: 5px; } }

    &_questionArea {
      width: 100%;
      position: relative;
      border-bottom: 1px solid rgba(#0f54b9, .5);

      input {
        width: calc(100% - 50px);
        height: 50px;
        padding: 10px;
        border: 0; }

      &_btn {
        font-size: 1.8rem;
        order: 1;
        background: #ff6700;
        border: 0 none;
        color: #fff;
        position: absolute;
        bottom: 10px;
        right: 5px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 20px;
          width: 20px; } }

      &_info {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        background: #fff;

        .info_el {
          cursor: pointer;
          background: #ff6700;
          padding: 5px 10px;
          font-size: 14px;
          margin: 5px;
          width: calc(50% - 10px);
          color: #fff;
          border-radius: 10px;
          transition: .5s;
          transform-origin: 100% 50%;
          &.active {
            transform: scale(1.1); } } } } }
  .files_flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }
  &_file {
    position: relative;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.2;
    text-decoration: underline;
    &:last-child {
      margin-right: 0; }
    img {
      height: 20px;
      margin-right: 10px; } } }







