.partners {
  width: 100%;
  &__head {
    font-size: 48px;
    line-height: 56px;
    color: #000000;
    margin-bottom: 39px;
    @include for-phone {
      font-size: 24px;
      line-height: 28px; } }
  &__flex {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    @include for-phone {
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .partner_el {
      width: calc(25% - 20px);
      min-width: 187px;
      margin-right: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      @include for-phone {
        margin-right: 0;
        margin-bottom: 20px; }
      &-icon {
        display: inline-block;
        height: 187px;
        width: 187px;
        border-radius: 187px;
        overflow: hidden;
        background: rgba(#C4C4C4, .2);
        cursor: pointer;
        transition: .5s;
        @include for-phone {
          height: 80px;
          width: 80px; }
        &:hover {
          background: #FF6700; } }
      &-img {
        margin-left: auto;
        margin-right: auto;
        padding-top: 50%;
        padding-bottom: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 150px;
          max-height: 150px;
          object-fit: contain; } }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0; }

      &-desc {
        .name {
          margin-top: 32px;
          font-size: 24px;
          line-height: 30px;
          color: #000000;
          text-align: center;
          @include for-phone {
            margin-top: 15px;
            font-size: 16px;
            line-height: 1.2; } }

        .description {
          margin-top: 16px;
          font-size: 18px;
          line-height: 24px;
          color: rgba(#000000, .5);
          @include for-phone {
            font-size: 14px;
            line-height: 1.2; }
          a {
            color: inherit;
            text-decoration: none; } } } } } }








