.footer {
  width: 100%;
  padding-top: 93px;
  padding-bottom: 27px;
  background: #fff;
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      margin-top: 238px;
      @include for-phone {
        margin-top: 47px;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
    &-el {
      font-size: 15px;
      line-height: 17px;
      color: rgba(#000000, .5);
      transition: .5s;
      @include for-phone {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0; } }
      &.onTop {
        cursor: pointer;
        transition: .5s;
        &:hover {
          color: #FF6700; } } }
    a {
      &:hover {
        color: #FF6700; } }
    .social {
      display: flex;
      justify-content: center;
      align-items: center;
      &__el {
        margin: 0 17px;
        path {
          transition: .5s;
          opacity: 1; }
        &:hover {
          path {
            opacity: 1; } }
        //&-vk
        //  &:hover
        //    path
        //      opacity: 1
        //      fill: #4c75a3
        //&-fb
        //  &:hover
        //    path
        //      opacity: 1
        //      fill: #3b5999
        //&-insta
        //  &:hover
        //    path
        //      opacity: 1
 } } }        //      fill: #e4405f


  .navigation__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include for-phone {
      flex-direction: column;
      align-items: flex-start; }
    &-el {
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      margin-right: 68px;
      @include for-phone {
        margin-right: 0;
        margin-bottom: 20px; }

      &:last-child {
        margin-right: 0; } } }


  &.fVmeste {
    .footer__row:first-child {
      display: none; }
    .footer__row:last-child {
      margin-top: 0; } } }

