* {
  box-sizing: border-box;
  outline: none; }

a,
button {
  transition: .5s;
  text-decoration: none;
  outline: 0 none; }
.wrp {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background: #fff;
  font-family: $fontBase;
  .page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column; }
  .cnt {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    padding: 0 20px;
    &__visible {
      overflow: visible; } }
  .no-wrap {
    white-space: nowrap; } }
