.forumDatEvents__page {
  .banner {
    min-height: 575px; }
  .banner__bg {
    object-position: top left;
    object-fit: cover; }
  .banner__head {
    color: #000000; }
  .banner__text {
    color: #000000;
    opacity: 0.5; }

  .projects__all {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    .partner_el {
      width: calc(33% - (20px / 3 * 2));
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      display: none;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        display: flex; }
      &:nth-child(3n) {
        margin-right: 0; }
      @include for-phone {
        width: 100%; } }
    .partner_el-icon {
      width: 100%;
      height: 324px;
      border-radius: 20px; }
    .partner_el-img {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      img {
        position: static;
        transform: translate(0);
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        object-fit: cover;
        object-position: center; } }


    &.show {
      .partner_el {
        display: flex; } }
    .partners__btns {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 42px;
      margin-bottom: 50px; } } }

